<template>
  <div class="pc" ref="vlbox">
    <div class="index_main" ref="index_main">
      <div class="index_right">
        <p class="search">
          <el-select v-if="permissoin.includes('query')" clearable v-model="searchForm.state" size="mini">
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
          v-if="permissoin.includes('query')"
            type="text" clearable
            size="mini"
            v-model="searchForm.name"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1" v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2" v-if="permissoin.includes('add')"
            @click="
              modal = 1;
              modalState = 0;
            "
          >
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="name" label="项目名称" width="280" :show-overflow-tooltip="true">
            </el-table-column>
            <!-- <el-table-column prop="name" label="回款条目" width="200" :show-overflow-tooltip="true"> </el-table-column> -->
            <el-table-column prop="company" label="付款单位" width="240" :show-overflow-tooltip="true"> </el-table-column>
            <!-- <el-table-column prop="orgName" label="回款金额">
            </el-table-column> -->
            <el-table-column prop="orgName" label="回款类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">项目</span>
                <span v-if="scope.row.type == 2">代理商</span>
                <span v-if="scope.row.type == 3">其他</span>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="回款人"> </el-table-column>
            <el-table-column prop="amount" label="回款金额">
                <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.amount,2)}}</span>
                    </template>
               </el-table-column>
            <el-table-column prop="createTime" label="回款日期">
            </el-table-column>
            <el-table-column prop="orgName" label="回款状态">
              <template slot-scope="scope">
                <span v-if="scope.row.state == 1" class="colorgreen">已到账<span v-if="scope.row.timeout == 2" class="colorred">（超时）</span></span>
                <span v-if="scope.row.state == 2" class="colororange">待审核 
                  <span v-if="scope.row.timeout == 2" class="colorred">（超时）</span>
                </span>
                <span v-if="scope.row.state == 3" class="colorred">驳回<span v-if="scope.row.timeout == 2" class="colorred">（超时）</span></span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-search"
                      v-if="permissoin.includes('query')"
                      :command="{ num: 20, data: scope.row }"
                      >查看</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-if="scope.row.state == 2&&permissoin.includes('examine')"
                      :command="{ num: 2, data: scope.row }"
                      >审核</el-dropdown-item
                    >
                    <!-- <el-dropdown-item
                    icon="el-icon-delete" 
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner" v-loading="loading" element-loading-text="数据提交中"
                 element-loading-spinner="el-icon-loading">
        <div class="inner">
          <p class="title">
            <span v-if="modalState == 0">新增回款</span>
            <span v-else>回款详情</span>
            <span
              @click="
                modal = 0;
                form = {projectId1:{},projectId2:{},name:''};
              "
              class="pointer"
            >
              <i class="el-icon-close"></i>
            </span>
          </p>
          <div class="modal_center scrollbar_width0">
            <p>
              <span class="spaa"><span class="star">* </span>回款类型</span>
              <el-select v-model="form.type" :disabled='modalState != 0' size="mini" placeholder="请选择">
                <el-option
                  v-for="item in option2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p>
                  <p v-show="form.type==3&&modalState == 0">
              <span class="spaa"><span class="star">* </span>回款来源</span>
                <el-radio-group v-model="form.type1" size="mini">
                  <el-radio :label="1">项目</el-radio>
                  <el-radio :label="2">代理商</el-radio>
                </el-radio-group>
            </p>
                      <p v-show="form.type1 == 1&&form.type == 3">
              <span class="spaa"><span class="star">* </span>项目名称</span>
              <el-select
              clearable filterable
              @change="form.name=form.projectId.constructionUnit"
              value-key='id'
                v-model="form.projectId"
                size="mini"
                placeholder="请选择"
                :disabled='modalState != 0'
              >
                <el-option
                  v-for="item in option5"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.type1 == 2&&form.type == 3">
              <span class="spaa"><span class="star">* </span>代理商</span>
              <el-select
              clearable filterable
              @change="form.name=form.projectId.name"
              value-key='id'
              :disabled='modalState != 0'
                v-model="form.projectId"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option6"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.type == 1">
              <span class="spaa"><span class="star">* </span>项目名称</span>
              <el-select
              clearable filterable
              @change="form.name=form.projectId.constructionUnit"
              value-key='id'
                v-model="form.projectId"
                size="mini"
                placeholder="请选择"
                :disabled='modalState != 0'
              >
                <el-option
                  v-for="item in option5"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.type == 2">
              <span class="spaa"><span class="star">* </span>代理商</span>
              <el-select
              clearable filterable
              @change="form.name=form.projectId.name"
              value-key='id'
              :disabled='modalState != 0'
                v-model="form.projectId"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option6"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </p>
            <p>
              <span class="spa">付款单位</span>
              <el-input :disabled='modalState != 0' v-model="form.name" size="mini"></el-input>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>收款日期</span>
              <el-date-picker
              :disabled='modalState != 0'
                v-model="form.sdatatime"
                type="date"
                value-format="yyyy-MM-dd"
                size="mini"
                placeholder="选择日期"
              >
              </el-date-picker>
            </p>

            <p>
              <span class="spaa"><span class="star">* </span>收款方式</span>
              <el-select
              :disabled='modalState != 0'
                @change="zhChange"
                v-model="form.payType"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option3"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p>
            <p v-show="form.payType!=1">
              <span class="spa">收款账户</span>
              <el-select
              :disabled='modalState != 0'
              clearable filterable
                v-model="form.bankAccountId"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option8"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                <span style=" margin-right:12px;color: #333; font-size: 12px">{{ item.name }}</span>
                <span style=" margin-right:12px;color: #333; font-size: 12px">(****{{ item.account.substr(-4) }})</span>
                </el-option>
              </el-select>
            </p>
            <p>
              
              <span class="spaa"><span class="star">* </span>收款金额</span>
              <el-input :disabled='modalState != 0' v-model="form.amount" size="mini"></el-input>
            </p>
            <p v-show="modalState == 3||modalState == 2">
              <span class="spa">审批意见</span>
              <el-input v-model="form.describe" size="mini"></el-input>
            </p>
          </div>
          <div class="bottom" v-show="modalState == 0">
            <button class="btn_red" @click="onsubmit">保存</button>
            <button class="btn_gray" @click="modal = 0">取消</button>
          </div>
          <div class="bottom" v-show="modalState == 2">
            <button class="btn_red" @click="onsubmit1(1)">通过</button>
            <button class="btn_gray" @click="onsubmit1(2)">驳回</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  repaymentAdd,
  repaymentGetList,
  wxGetOrgList,
  merchantGetList,
  repaymentInfo,
  getListByPay,
  repaymentExamine,
} from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      form: {projectId1:{},projectId2:{},name:''},
      info: 1,
      currentPage2: 1,
      treeData: [],
      hide: 1,
      modal: 0,
      treeList: [],
      total: 0,
      searchForm: {},
      permissoin: [],
      projectP: -1,
      dialogVisible: false,
      netArrUpdate: [{ id: 0 }],
      option4: [],
      option2: [
        { name: "项目", id: 1 },
        { name: "代理商", id: 2 },
        { name: "其他", id: 3 },
      ],
      option3: [
        { name: "现金", id: 1 },
        { name: "电子汇款", id: 2 },
        { name: "对公账户", id: 3 },
      ],
      option5: [],
      option6: [],
      option8: [],
      ruleForm: {
        pwd: 123456,
      },
      tableData1: [],
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      modalState: 0,
      option1: [
        {
          value: "2",
          label: "待审核",
        },
        {
          value: "1",
          label: "已到账",
        },
        {
          value: "3",
          label: "驳回",
        },
      ],
      tableData: [],
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }],
      },
      loading:false,
    };
  },
  methods: {
    getData() {
      let data = {
        name: this.searchForm.name,
        state: this.searchForm.state,
        pageNum: this.currentPage2,
        pageSize: 10,
      };
      repaymentGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
         fmoney(val) {
      if(val){
 //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }else{
        return '0'
      }    
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    //审核
    onsubmit1(i) {
      repaymentExamine({
        id: this.form.id,
        state: i,
        describe: this.form.describe,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getData();
          this.modal = 0;
          this.form = {projectId1:{},projectId2:{},name:''};
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    zhChange(i) {
      let data = { type: i-1 };
      getListByPay(data).then((res) => {
        if (res.code == 200) {
          this.option8 = res.data;
        } else {
          this.option8 = [];
          this.$message.error(res.data.info)
        }
      });
    },
    onsubmit() {
      let data = {
        type:this.form.type1?3:this.form.type,
        projectId:this.form.projectId.id,
        company:this.form.name,
        sdatatime: this.form.sdatatime,
        payType: this.form.payType,
        bankAccountId: this.form.bankAccountId,
        amount: this.form.amount,
      };
      this.loading = true
      repaymentAdd(data).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.getData();
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.modal = 0;
          this.form = {projectId1:{},projectId2:{},name:''};
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleCommand(d) {
      if (d.num == 20 || d.num == 2) {
        //查看详情
        repaymentInfo({ id: d.data.id }).then((res) => {
          if (res.code == 200) {
            this.modalState = d.num;
            this.form = res.data;
            this.form.projectId1 = res.data.name;
            this.form.projectId2 = res.data.name;
            this.form.type = d.data.type;
            this.form.bankAccountId = this.form.bankName;
            this.modal = 1;
          } else {
            this.$message.error(res.data.info);
          }
        });
      }
    },
  },
  mounted() {
    //获取权限
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){
        this.permissoin.push(item.permission)
      }
    })
    this.getData();
    wxGetOrgList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option5 = res.data.list;
      }
    });
    merchantGetList({ pageSize: 0,type:2 }).then((res) => {
      if (res.code == 200) {
        this.option6 = res.data.list;
      }
    });
  },
};
</script>

<style lang="less" >
.pc {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding: 0 34px;
        box-sizing: border-box;
        padding-top: 20px;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 71px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-input {
            width: 270px;
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 348px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>